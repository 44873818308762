import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Books = () => {
  return (
    <Layout>
      <Seo title="Books" />
    </Layout>
  )
}

export default Books;